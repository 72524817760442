<template>
  <div>
    <!-- 切换 -->
    <div class="tab">
      <div class="tab-title d-flex align-center">
        {{ subjectsIndex.subjectName }}
        <el-dropdown @command="handleCommand" trigger="click">
          <div class="tab-img cursor">
            <img src="../../assets/img/tab_title.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(it, idx) in subjects"
              :key="idx"
              :command="idx"
              >{{ it.subjectName }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="simulation">
      <ul class="list">
        <li
          class="item d-flex align-center justify-between"
          v-for="(item, index) in dailyList"
          :key="index"
        >
          <div class="item-l d-flex align-center">
            <div class="item-name">
              <!-- <span>【章节练习】</span> -->
              {{ item.userExamHistory.examName }}
              <div class="item-time">
                {{ item.userExamHistory.endtime }}
                <!-- <span>总85题/对4题</span> -->
              </div>
            </div>
          </div>
          <div class="item-r d-flex align-center">
            <!-- <div class="download d-flex align-center">查看记录</div> -->
            <div
              class="item-btn d-flex align-center justify-center cursor"
              :style="btn"
              @click="dailyBtn(item)"
            >
              查看
            </div>
          </div>
        </li>
      </ul>
      <div
        v-if="dailyList == ''"
        class="kong d-flex align-center flex-column justify-center"
      >
        <img class="kong-img" src="../../assets/img/kong.png" alt="" />
        <div class="kong-txt">暂无内容</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cutIndex: 0,
      subjects: "",
      subjectsIndex: "",
      isReady: false,
      btn: {
        backgroundImage: "url(" + require("../../assets/img/go_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      dailyList: ""
    };
  },
  mounted() {
    this.getClass();
  },
  methods: {
    handleCommand(command) {
      this.subjectsIndex = this.subjects[command];
      this.errorShow();
    },
    //科目列表
    async getClass(params) {
      let _this = this;
      const res = await this.$ajaxRequest('get', 'initDatatiku')
      console.log(res.data);
      _this.subjects = res.data;
      _this.subjectsIndex = res.data[0];
      _this.errorShow();
      this.isReady = true;
    },
    async errorShow(params) {
      let _this = this;
      let data = {
        subjectId: _this.subjectsIndex.subjectId,
        start: 1,
        limit: 20
      }
      const res = await this.$ajaxRequest('get', 'errorShow', data)
      console.log(res.data);
      _this.dailyList = res.data;
    },
    dailyBtn(item) {
      console.log(item);
      this.$router.push({
        path: `wrongExam`,
        query: {
          subjectId: item.userExamHistory.id
          // examtype: item.userExamHistory.examtype,
          // examId: item.userExamHistory.examId,
          // name: item.userExamHistory.examName
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
//切换选中状态
.tab_active {
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-color: #5f2eff !important;
  color: #5f2eff;
  .img1 {
    opacity: 0 !important;
  }
  .img2 {
    top: 0 !important;
  }
}
//切换
.tab {
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  background: #ffffff;
  padding: 0px 29px;
  box-sizing: border-box;
  .tab-title {
    padding: 36px 0px 22px 8px;
    font-size: 20px;
    font-weight: bold;
    color: #444444;
    box-sizing: border-box;
    img {
      width: 82px;
      height: 26px;
      margin-left: 20px;
    }
  }
}
.simulation {
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  margin-top: 20px;
  padding-bottom: 30px;
  box-sizing: border-box;
  min-height: 558px;
  .list {
    padding: 26px 36px;
    box-sizing: border-box;
    .item {
      height: 96px;
      background: #ffffff;
      box-sizing: border-box;
      border-bottom: 1px solid #f6f9ff;
      .item-l {
        .item-img {
          height: 33px;
          width: 33px;
          margin-right: 20px;
        }
        .item-name {
          font-size: 18px;
          color: #333333;
          span {
            font-size: 18px;
            color: #5f2eff;
          }
          .item-time {
            color: #999999;
            font-size: 14px;
            // margin-left: 7px;
            margin-top: 10px;
            span {
              margin-left: 23px;
              color: #999999;
              font-size: 14px;
            }
          }
        }
      }
      .item-r {
        .download {
          font-size: 16px;
          color: #999999;
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .item-btn {
          width: 104px;
          height: 43px;
          font-size: 16px;
          color: #ffffff;
          margin-left: 40px;
        }
      }
    }
  }
}
</style>
